/***** import *****/
import lightGallery from 'lightgallery';
import lgZoom from 'lightgallery/plugins/zoom';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';

/***** Module animal-reader *****/
lightGallery(document.getElementById('animal-reader__light-gallery'), {
  speed: 500,
  mode: 'lg-fade',
  selector: '#animal-reader__light-gallery a[data-lightbox]'
});
