window.onload = function () {

  /* show / hide main nav
  -------------------------------------------------- */

  const btnnav = document.querySelector('.js-toggle-button');
  const mobilenav = document.querySelector('.js-nav');

  // Toggle menu to visible
  btnnav.addEventListener("click", () => {
    mobilenav.classList.toggle('is-open');
  });

  // Toggle burger animation
  btnnav.onclick = function () {
    btnnav.classList.toggle('toggle'); // nav animation
  };

};