/**
 * Glide
 */
//import Glide from '@glidejs/glide'

window.onscroll = function () {
  /***** RSCE support-us (feat. pageImage) *****/
  let supportButton = document.querySelector(".support-us__trigger");
  if (supportButton) {
    if (supportButton.parentElement.getBoundingClientRect().top > 0) {
      supportButton.classList.remove("is-fixed");
    } else {
      supportButton.classList.add("is-fixed");
    }
  }

  /***** ScrollTop - Button *****/
  let body = document.body, html = document.documentElement;
  let height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
  let scrollTopButton = document.querySelector(".btn-scrolltop");

  if (window.scrollY > (height / 2)) {
    scrollTopButton.classList.add("active");
  } else {
    scrollTopButton.classList.remove("active");
  }
}

window.onload = function () {

  /**
   * Mobile navigation
   */
  let btnnav = document.querySelector(".js-togglemenu");
  if (btnnav) {
    btnnav.onclick = function () {
      btnnav.classList.toggle("active");
    }
  }

  /***** RSCE card-slider - glide *****/
  // if (document.querySelector('.card-slider .glide')) {
  //   new Glide(".card-slider .glide", {
  //     type: 'carousel',
  //     gap: 30,
  //     perView: 4,
  //     autoplay: 100000,
  //     focusAt: 0,
  //     breakpoints:
  //       {
  //         992: {
  //           perView: 3
  //         },
  //         768: {
  //           perView: 2
  //         },
  //         576: {
  //           perView: 1
  //         }
  //       }
  //   }).mount();
  // }

  /***** RSCE support-us (feat. pageImage) *****/
  let supportButton = document.querySelector(".support-us__trigger");
  if (supportButton) {
    // set position of support trigger if pageimage is found
    let pageImage = document.querySelector("#pageimage");
    let target = document.querySelector(supportButton.getAttribute("href"));
    if (pageImage && target) {
      supportButton.classList.add("active");
    }

    // add click event handler for support trigger
    supportButton.onclick = function (e) {
      e.preventDefault();
      scroll({
        top: target.parentElement.parentElement.getBoundingClientRect().top + document.documentElement.scrollTop,
        behavior: "smooth"
      });
    }
  }

  /***** Module animal-list *****/
  let animalListFilters = document.querySelectorAll(".animal-list__filter-item");
  let animalListItems = document.querySelectorAll(".animal-list__item");
  let animalList = document.querySelector(".animal-list__list");

  function filterClicked(filter) {
    let filterType = filter.getAttribute("data-type");

    // toggle filter
    if (!filter.classList.contains("active")) {
      animalListFilters.forEach(function (filter) {
        filter.classList.remove("active");
      });
    }
    filter.classList.toggle("active");

    // toggle items
    animalListItems.forEach(function (item) {
      let itemType = item.getAttribute("data-type");
      item.classList.add("inactive");

      setTimeout(() => {
        if (filterType && itemType !== filterType && filter.classList.contains("active")) {

        } else {
          item.classList.remove("inactive");
        }
      }, 500);
    });
  }

  animalListFilters.forEach(function (filter) {
    // add event listeners
    filter.onclick = function () {
      filterClicked(filter);
    };

    // handle url get parameter
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("filter") === filter.getAttribute("data-type")) {
      filterClicked(filter);
    }
  });

  /***** scrolltop - button *****/
  let scrollTopButton = document.querySelector(".btn-scrolltop");
  scrollTopButton.addEventListener("click", function () {
    window.scrollTo({top: 0, behavior: "smooth"});
  });

  /***** random image in support-us element *****/
  let items = [111, 112, 113, 114];
  let item = items[Math.floor(Math.random() * items.length)];
  let freePlate = document.querySelector("[data-item=\"" + item + "\"]");
  if (freePlate) {
    freePlate.classList.add("show");
  }
}
