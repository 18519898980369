/***** import *****/

import Swiper, {Navigation, Pagination, Scrollbar} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

/***** Module animal-carousel *****/

new Swiper('.animal-carousel__slider', {
  modules: [Navigation, Pagination, Scrollbar],
  slidesPerView: 1.3,
  spaceBetween: 30,
  loop: false,
  centeredSlides: true,
  // pagination: {
  //   el: '.swiper-pagination',
  //   type: 'bullets',
  //   clickable: true
  // },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  scrollbar: {
    el: '.swiper-scrollbar',
    draggable: true,
  },
  breakpoints: {
    576: {
      slidesPerView: 2,
      centeredSlides: false,
    },
    768: {
      slidesPerView: 3,
      centeredSlides: false,
    },
    992: {
      slidesPerView: 4,
      centeredSlides: false,
    }
  }
});